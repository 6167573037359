import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function HomePage() {
  return (
    <div className="background-image">
      <div className="home-page-text">
        <h1>Feetdrip</h1>
        <p>The best AI pictures sharing platform</p>
        <p>more to come...</p>
        <Link to="/terms-and-conditions">View Terms and Conditions</Link>
        <br />
        <a
          href={`https://play.google.com/store/apps/details?id=com.lemadhatter_66.FeetDrip`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={`${process.env.PUBLIC_URL}/128x128.png`} alt="Get it on Google Play" />
        </a>
      </div>
    </div>
  );
}

export default HomePage;