// Update your JSX to include the CSS classes
import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function TermsAndConditions() {
  return (
    <div className="background-image">
      <div className="TermsAndConditions">
        <h1>Terms and Conditions</h1>
        <p>
          <strong>Effective Date:</strong> January 2024
          <br />
          Welcome to FEETDRIP By accessing or using our mobile application (the "App"), you agree to be bound by these Terms and Conditions (the "Terms"). Please read them carefully.
        </p>
        <h4>1. Acceptance of Terms</h4>
        <p>
          By using our App, you agree to these Terms, our Privacy Policy, and any additional terms that may apply to specific features or services offered through the App. If you do not agree with these Terms, please do not use the App.
        </p>
        <h4>2. Account Registration</h4>
        <p>
          To use certain features of the App, you may need to create an account. You agree to provide accurate and complete information during registration and to update such information to keep it accurate and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </p>
        <h4>3. Use of the App</h4>
        <p>
          You agree to use the App only for lawful purposes and in accordance with these Terms. You must not:
          <ul>
            <li>Use the App to engage in any illegal, fraudulent, or abusive activities.</li>
            <li>Post, upload, or distribute any content that is illegal, offensive, or violates the rights of others.</li>
            <li>Attempt to interfere with or disrupt the App’s functionality.</li>
          </ul>
          And you must:
          <ul>
            <li>
              Be 18 years old or older.
            </li>
          </ul>
        </p>
        <h4>4. Content and Intellectual Property</h4>
        <p>
          The App provides a platform for users to buy and sell feet pictures. All content, including but not limited to text, images, and logos, is owned by or licensed to FEETDRIP and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from the App without our express permission.
        </p>
        <h4>5. Transactions</h4>
        <p>
          All transactions conducted through the App are subject to our payment terms and conditions. You agree to pay all applicable fees (4% per transaction) and charges related to your purchases. FEETDRIP reserves the right to modify fees and charges at any time.
        </p>
        <h4>6. User-Generated Content</h4>
        <p>
          By submitting or posting content to the App, you grant FEETDRIP a non-exclusive, royalty-free, perpetual, and worldwide license to use, display, and distribute such content. You are responsible for ensuring that you have the necessary rights to any content you submit.
        </p>
        <h4>7. Dispute Resolution</h4>
        <p>
          Any disputes arising from the use of the App or these Terms will be resolved in accordance with the laws of Canada. You agree to submit to the exclusive jurisdiction of the courts located in Canada.
        </p>
        <h4>8. Limitation of Liability</h4>
        <p>
          To the maximum extent permitted by law, FEETDRIP will not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App.
        </p>
        <h4>9. Changes to Terms</h4>
        <p>
          We reserve the right to modify these Terms at any time. Any changes will be effective when we post the revised Terms on the App. Your continued use of the App after any changes constitutes your acceptance of the new Terms.
        </p>
        <h4>10. Contact Information</h4>
        <p>
          If you have any questions or concerns about these Terms, please contact us at:
          <br />
          FEETDRIP
          <br />
          feetdrip000@proton.me 
          <br />
        </p>
        <Link to="/">Back to homepage <br /></Link>
        <br />
      </div>
    </div>
  );
}

export default TermsAndConditions;